import { Theme } from "@cloudscape-design/components/theming";
export const brandTheme: Theme = {
  tokens: {
    // Values are applied globally, except for visual context
    // Shorter syntax to apply the same value for both light and dark mode
    colorTextAccent: "#0073bb",
    //fontFamilyBase: "'Helvetica Neue',Arial, sans-serif",
   // borderRadiusButton: "10px",
   // borderRadiusContainer: "4px",
    colorBackgroundContainerHeader: "#232f3e",
    colorTextHeadingDefault: "#ffffff",
   // borderRadiusToken: "2px"
  },
  contexts: {
    // Values for visual contexts. Unless specified, default values will be applied
    'top-navigation': {
         tokens: {
            colorBackgroundContainerContent: '#007ac2',
            colorBackgroundDropdownItemDefault:'#007ac2',
            colorTextAccent: "#ffffff",
            borderRadiusDropdown:"0px",
            borderRadiusContainer: "2px"
         },
    }
  }
  
};
