import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import '@cloudscape-design/global-styles/index.css';
import App from './App';
import { Authenticator } from '@aws-amplify/ui-react';


ReactDOM.render(
    <HashRouter>
   <Authenticator.Provider>
        <App />
  </Authenticator.Provider>
    </HashRouter>,
    document.getElementById('root')
);
